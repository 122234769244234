import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'
import * as XLSX from 'xlsx'
import Loader from 'react-js-loader'
import Swal from 'sweetalert2'
import $ from 'jquery';
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net';

const SucWithdraw = () => {
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState()
      const [userExport, setExport] = useState()
  const meedata = localStorage.getItem("uData");
  const userData = JSON.parse(meedata);
    const [startDate, setStartDate] = useState(null)
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API
  const nodeMode = process.env.NODE_ENV
  if (nodeMode === 'development') {
    var baseUrl = beckendLocalApiUrl
  } else {
    baseUrl = beckendLiveApiUrl
  }

  let [limit, setLimit] = useState(10)

  const setpageLimit = event => {
    let key = event.target.value
    setLimit(key)
  }
  const [pageNumber, setPageNumber] = useState(0)
  const [numberOfPages, setNumberOfPages] = useState(0)
  //user for searching..
  const [searchList, setSearchList] = useState(0)
  const [searchType, setSearchType] = useState("Phone")
  const [findByStatus, setFindByStatus] = useState(0)

  //react paginate..
  const handlePageClick = async data => {
    let currentPage = data.selected + 1
    setPageNumber(currentPage)
    // scroll to the top
    //window.scrollTo(0, 0)
  }

  //   searching handler
  const searchHandler = event => {
    let key = event.target.value
    setSearchList(key)
  }
  //   search by status handler
  const searchByStatus = event => {
    setSearchType("0")
    let key = event.target.value
    setFindByStatus(key)
  }

  const profle = () => {
    // setLoading(true)
    // Swal.showLoading(Swal.getDenyButton())
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios
      .get(
        baseUrl +
          `admin/txn/withdraw_admin/all?page=${pageNumber}&_limit=${limit}&_q=${searchList}&_stype=${searchType}&_status=${findByStatus}&date=${startDate}`,
        { headers }
      )
      .then(res => {
        setUser(res.data.data)
        setNumberOfPages(res.data.totalPages)
        // Swal.hideLoading( {timer: 1500})
        // setLoading(false)
      })
  }

  const profleExport = () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios
      .get(
        baseUrl +
        `admin/txn/withdraw_admin/all`,
        { headers }
      )
      .then(res => {
        setExport(res.data.data)
      }).catch(e => {
        setLoading(false)
      })
  }

  const [withdrawSuccess, setWithdrawSuccess] = useState()
  const [withdrawFail, setWithdrawFail] = useState()

  const withdrowPass = id => {
    const confirm = window.confirm(
      'Are you sure, you want to update to success this payout?'
    )
    if (confirm) {
      const access_token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${access_token}`
      }

      axios
        .post(
          baseUrl + `userwithdrawupdate/${id}`,
          {
            status: 'SUCCESS'
          },
          { headers }
        )
        .then(res => {
          profle()
          alert('Payout successfully done')
        })
        .catch(e => {
          //console.log(e);
        })
    }
  }

  const withdrowFail = id => {
    const confirm = window.confirm(
      'Are you sure, you want to update to failed this payout?'
    )
    if (confirm) {
      const access_token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${access_token}`
      }

      axios
        .post(
          baseUrl + `userwithdrawupdate/${id}`,
          {
            status: 'FAILED'
          },
          { headers }
        )
        .then(res => {
          profle()
          alert('Payout successfully reject')
          //console.log(res);
        })
        .catch(e => {
          //console.log(e);
        })
    }
  }

  const universalCheckPayout = (payment_gatway, txn_id, referenceId, userID) => {
    //alert(payment_gatway);
    if (!referenceId) {
      alert('Payout txn id not found')
    }
    if (payment_gatway == 'razorpay') {
      checkrazorpaypayout(txn_id, referenceId)
    }
    else if(payment_gatway == 'KVMPAY'){
      checkkvmPayPayment(txn_id,referenceId)
    } else if (payment_gatway == 'qrologic') {
      checkqrologic(txn_id, referenceId, userID)
    } else if (payment_gatway == 'pinelab') {
      //checkPinelabpayout(txn_id, referenceId)
    } else {
      alert('Nothing found')
    }
  }

  const checkqrologic = (txn_id, referenceId, userID) => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios
      .post(
        baseUrl + `qrologic/txnstatus`,
        { txn_id, referenceId, userID },
        { headers }
      )
      .then(res => {
        const icon = res.data.status == 'SUCCESS' ? 'success' : 'danger'
        const title = res.data?.message

        profle()
        Swal.fire({
          title: title,
          icon: icon,
          confirmButtonText: 'OK'
        })
      })
      .catch(e => {
        if (e.response.status == 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('uData');
          window.location.reload()
        }
      })
  }

  const checkrazorpaypayout = (txn_id, referenceId) => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios
      .post(
        baseUrl + `razorpaypayoutscheck/response`,
        { txn_id, referenceId },
        { headers }
      )
      .then(res => {
        const icon = res.data.status == 'SUCCESS' ? 'success' : 'danger'
        const title =
          res.data.status == 'SUCCESS'
            ? 'Withdraw successfully'
            : 'Transaction Proccessing or Failed'

        profle()
        Swal.fire({
          title: title,
          icon: icon,
          confirmButtonText: 'OK'
        })
      })
      .catch(e => {
        if (e.response.status == 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('uData');
          localStorage.removeItem('PData');
          window.location.reload()
        }
      })
  }
  const checkkvmPayPayment = (txn_id,referenceId) => {
    setLoading(true)
    const access_token = localStorage.getItem('token')
    const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      axios
        .post(
          baseUrl + `kvmpay-payout-status`,
          { txn_id,referenceId :txn_id},
          { headers }
        )
      .then(res => {
    setLoading(false)
    Swal.fire({
      title: res.data.status === 'success'  ? 'Success' : 'Error',
      text: res.data.message,
      icon: res.data.status === 'success' ? 'success' : 'error' // Changed 'danger' to 'error'
    });
        profle()
      })
      .catch(e => {
    setLoading(false)
        if (e.response.status == 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('uData');
          localStorage.removeItem('PData');
          window.location.reload()
        }
      })
  }

  function daterange (s, e) {
  if (s) {
    setStartDate(s)
  }
   
  }

  const dateFormat = e => {
    const date = new Date(e);
  
    const day = date.getDate();  // Day
    const month = date.toLocaleString('default', { month: 'short' });  // Short month (Dec)
    const time = date.toLocaleTimeString('default', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });  // Time with AM/PM
  
    return `${day} ${month}  ${time}`;
  }
  

  let currentTime = Date.now()

  const imageopen = (image) => {
    Swal.fire({
      title: "Pay Qr Code",
      text: "",
      imageUrl: baseUrl+image,
      imageWidth: 400,
      imageHeight: 300,
      imageAlt: "Custom image"
    })
  }


   // excel data downlord
        const createDownloadData = () => {
          handleExport()
        }
        const handleExport = () => {
          let table1 = [
            {
              A: 'Name',
              B: 'Withdraw Amount',
              C: 'Withdraw Status'
            }
          ]
      
          userExport.forEach(row => {
            const userDetails = row
            console.log('exldata', userDetails)
            table1.push({
              A: userDetails.User_id ? userDetails.User_id.Name : '',
              B: userDetails.amount ? userDetails.amount : '',
              C: userDetails.status ? userDetails.status : ''
            })
          })
      
          //table1 = [{A:"User Details"}].concat(table1);
          //const finalData = [...table1];
          //console.log(finalData);
          /* convert state to workbook */
          const ws = XLSX.utils.json_to_sheet(table1, {
            skipHeader: true
          })
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, ws, 'SheetJS')
          /* generate XLSX file and send to client */
          XLSX.writeFile(wb, 'WithdrawReport.xlsx')
        }


  useEffect(() => {
    profle()
  }, [pageNumber, limit, searchList, searchType, findByStatus, startDate])
  useEffect(() =>{
    profleExport()
  },[])

  if (user == undefined) {
    return null
  }
  return loading ? (
    <>
      <div className='App'>
        <div className='item dashboaed_loader'>
          <Loader
            type='bubble-ping'
            bgColor={'#fff'}
            title={'Loading Please Wait'}
            color={'#FFFFFF'}
            size={100}
          />
        </div>
      </div>
    </>
  ) : (
    <>
      {/* <h4 className='font-weight-bold my-3'>ALL CHALLANGES</h4> */}
      <div className='row mt-5'>
        <div className='col-12 grid-margin'>
          <div className='card'>
            <div className='card-body text-light table_bg'>
              <h4 className=' dashboard_heading'>withdrawal History</h4>
              <div>
            {userData?.user_type == 'Admin' ? (
              <button
                    onClick={() => {
                      createDownloadData()
                    }}
                    className='btn btn-primary mr-2'
                  >
                    Export Data
                  </button>
                   ) : (
                    ""
                   )}
              </div>
              <select
                className='form-control col-sm-1 m-1 bg-dark text-light'
                id='pagelimit'
                name='pagelimit'
                onChange={setpageLimit}
              >
                <option value='10'>Set limit</option>
                <option value='20'>20</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
                <option value='500'>500</option>
              </select>

              {/* searching */}
              <div className='row'>
                <select
                  className='form-control col-sm-3 m-2'
                  id='searchType'
                  name='searchtype'
                  onChange={e => setSearchType(e.target.value)}
                >
                  <option value='0'>Select Search by</option>
                  <option value='Phone'>Phone</option>
                  <option value='_id'>Transaction Id</option>
                  <option value='account_number'>Account Number</option>
                  <option value='amount'>Amount</option>
                </select>
                <input
                  type='text'
                  placeholder='Search...'
                  className='form-control col-sm-4 m-2'
                  onChange={searchHandler}
                />
                <h5>Or</h5>
                <select
                  className='form-control col-sm-3 m-2'
                  id='findByStatus'
                  name='findByStatus'
                  onChange={searchByStatus}
                >
                  <option value='0'>Search Status</option>
                  <option value='SUCCESS'>Success</option>
                  <option value='Pending'>Pending</option>
                  <option value='pending'>pending</option>
                  <option value='none'>Proccessing</option>
                  <option value='reject'>Reject</option>
                  <option value='FAILED'>Failed</option>
                </select>
              </div>
              <input
              type="datetime-local"
              name="DOB"
              placeholder="Select Data"
              value={startDate}   onChange={(e) => daterange(e.target.value)}
              required
            />

              <div className='table-responsive'>
                <table className='table text-light'>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th> ID</th>
                      <th> Phone</th>
                      <th> User</th>
                      <th> type</th>
                      <th> txn-msg</th>
                      <th>UPI ID</th>
                      <th>Order ID</th>
                      <th> Amount </th>
                      <th> Status </th>
                      <th> Approve By </th>
                      <th> Action </th>
                      <th> Time </th>
                      <th>Complete Time </th>
                    </tr>
                  </thead>

                  <tbody>
                    {user &&
                      user.map((data, key) => {
                        let currentTime = Date.now()
                        let gameCreatedAt = new Date(data.createdAt).getTime()
                        return (
                          <tr>
                            <td>{key + 1}</td>
                            <td>{data._id}</td>
                            {userData?.user_type == 'Admin' ? (
                          <td>{data.User_id?.Phone}</td>
                        ) : (
                          <td>{`${
                            '********' + data.User_id?.Phone.toString().slice(-2)
                          }`}</td>
                        )}

                            <td>
                              {data.User_id && (
                                <Link
                                  className='btn btn-sm btn-outline-info'
                                  to={`/user/view_user/${data.User_id._id}`}
                                >
                                  {data.User_id.Name}
                                </Link>
                              )}
                            </td>
                            <td>
                              <span className='pl-2'>{data.Withdraw_type}</span>
                            </td>
                            <td>
                              <span className='pl-2'>
                                {data.txn_msg}
                              </span>
                            </td>
                            <td>
                              <span className='pl-2'>
                                {data?.bankDetail?.upiId}
                              </span>
                            </td>
                            <td>
                              <span className='pl-2'>
                                {data.order_id}
                              </span>
                            </td>
                            <td>{data.amount}</td>
                          
                            <td className='font-weight-bold text-success'>
                              {data.status === 'none'
                                ? 'Proccessing'
                                : data.status}
                            </td>
                            <td className='font-weight-bold '>
                              {data?.action_by?.Name}
                            </td>
                            <td>
                              {data?.status == 'pending' && ((parseInt(gameCreatedAt) + 7200000) < currentTime) ?
                              (
                                <button
                                  className='btn btn-danger'
                                  onClick={() =>
                                    universalCheckPayout(
                                      data.payment_gatway,
                                      data._id,
                                      data.referenceId,
                                      data?.User_id?._id,
                                    )
                                  }
                                >
                                  Check {data.payment_gatway}
                                </button>
                              ) : (
                                'Checked All or Wait'
                              )}

                              {
                                // (data.status != "SUCCESS" && data.status != "FAILED" && data.status!="reject")? <button className="mr-1 btn btn-sm btn-danger" onClick={() => withdrowFail(data._id)}>Fail</button> :''
                              }

                              {
                                //   (data.status != "SUCCESS" && data.status != "FAILED" && data.status!="reject")? <button className="ml-1 btn btn-sm btn-warning" onClick={() => withdrowPass(data._id)}>Success</button>:''
                              }
                            </td>
                            <td>{dateFormat(data.createdAt)}</td>
                            <td>{dateFormat(data.updatedAt)}</td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>

              <div className='mt-4'>
                <ReactPaginate
                  previousLabel={'Previous'}
                  nextLabel={'Next'}
                  breakLabel={'...'}
                  pageCount={numberOfPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination justify-content-center'}
                  pageClassName={'page-item'}
                  pageLinkClassName={'page-link'}
                  previousClassName={'page-item'}
                  previousLinkClassName={'page-link'}
                  nextClassName={'page-item'}
                  nextLinkClassName={'page-link'}
                  breakClassName={'page-item'}
                  breakLinkClassName={'page-link'}
                  activeClassName={'active'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SucWithdraw
